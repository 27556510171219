/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';

window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo';

import Pusher from 'pusher-js';

window.Pusher = Pusher;
import {
    transChoice,
    trans,
    isLoaded,
    getActiveLanguage,
    loadLanguageAsync,
} from 'laravel-vue-i18n'
import {usePage} from "@inertiajs/vue3";

window.transChoice = transChoice;
window.trans = trans;
window.isLoaded = isLoaded;
window.getActiveLanguage = () => {
    const page = usePage();
    return page.props?.language ? page.props.language : localStorage.getItem('language') ?? 'de';
}
window.loadLanguageAsync = loadLanguageAsync;


window.Echo = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'mt1',
    wsHost: import.meta.env.VITE_PUSHER_HOST,
    wsPort: 6001,
    wssPort: 6001,
    forceTLS: false,
    encrypted: false,
    enabledTransports: ['wss', 'ws'],
});
