import './bootstrap';
import '../css/app.css';

import {createApp, h} from 'vue';
import {createInertiaApp} from '@inertiajs/vue3';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from '../../vendor/tightenco/ziggy/dist/vue.m';
import {i18nVue} from "laravel-vue-i18n";
import moment from 'moment/min/moment-with-locales';
import * as Sentry from "@sentry/vue";

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({el, App, props, plugin}) {
        const app = createApp({render: () => h(App, props)})
            .use(plugin)

            .use(i18nVue, {
                shared: true,
                fallbackLang: 'en',
                lang: props.initialPage.props.language ?? getActiveLanguage(),

                resolve: async lang => {
                    const langs = import.meta.glob('../lang/*.json');
                    return await langs[`../lang/${lang}.json`]();
                }
            })
            .use(ZiggyVue, Ziggy);

        Sentry.init({
            app,
            dsn: import.meta.env.SENTRY_LARAVEL_DSN,
            sendDefaultPii: true,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["*",],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });

        app.mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});


window.moment = moment;
window.moment.locale(getActiveLanguage());

function datetime_formatted(date) {
    
    return moment(date).format('L LT');
};

window.datetime_formatted = datetime_formatted;

